// stylelint-disable scss/dollar-variable-empty-line-before

// COLORS

$color-black: #000;
$color-white: #fff;
$color-red: #ff110f;
$color-gray: #ddd;
$color-success: #38bd6c;
$color-orange: #f99900;
$color-yellow: #fd0;

// FONTS
$font-family-open-sans: "Open Sans", sans-serif;
