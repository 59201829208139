@import "variables";

*,
*::before,
*::after {
    box-sizing: inherit;
}

html {
    box-sizing: border-box;
}

body {
    overflow-x: hidden;
    font-family: $font-family-open-sans;
    font-size: 16px;
}

.text-success {
    color: $color-success;
}

.text-danger {
    color: $color-red;
}

.text-warning {
    color: $color-orange;
}
