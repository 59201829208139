@import "../variables";

.table-striped {
    table {
        margin-bottom: 1rem;
        border-collapse: collapse;
        width: 100%;
        max-width: 100%;
        background-color: transparent;
    }

    thead td {
        vertical-align: top;
        border-top: 1px solid #dee2e6;
        border-bottom: 1px solid $color-gray;
        padding: 0.75rem;
        font-weight: 600;
        font-size: 14px;
        line-height: 20px;
    }

    tbody tr {
        border: 1px solid $color-gray;
        width: 100%;
    }

    tbody tr:nth-of-type(odd) {
        background-color: rgba(0, 0, 0, 0.03);
    }

    tbody tr td {
        padding: 10px 5px;
        width: 50%;
    }

    tbody tr td:first-child {
        font-weight: 600;
        font-size: 14px;
        line-height: 20px;

        @media screen and (max-width: 641px) {
            border-bottom: 0;
        }
    }

    tbody tr td:last-child {
        font-size: 12px;
        line-height: 20px;
    }

    &[data-name="specs"] tbody tr td {
        word-break: break-all;
    }

    @media screen and (max-width: 641px) {
        table tbody:last-child tr:last-child td {
            border-bottom: 0;
        }
    }
}
