@import "../variables";

.gallery {
    .thumbnail {
        border: none;
    }

    .image-additional {
        .thumbnail {
            border: solid 1px $color-gray;

            &:hover {
                border-color: $color-red;
            }
        }
    }
}
