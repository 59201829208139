@import "../variables";

.breadcrumb {
    border: none;
    font-size: 12px;
    background: none;

    > li {
        padding: 0 15px;

        &::after {
            top: 4px;
            width: 10px;
            height: 10px;
        }

        a {
            text-decoration: underline;
            color: $color-black;

            &:hover {
                text-decoration: none;
            }
        }

        &:last-child {
            &::after {
                content: none;
            }
        }
    }
}
