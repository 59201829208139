@import "../variables";

.nav-tabs {
    margin-bottom: 30px;
    border-bottom: solid 2px $color-gray;

    > li {
        margin-bottom: -2px;

        > a {
            border: none;
            font-weight: 600;
            font-size: 18px;
            color: $color-gray;

            &:focus,
            &:hover {
                color: $color-black;
                background: none;
            }
        }

        &.active {
            a {
                border: none;
                border-bottom: solid 2px $color-red;
                font-weight: 600;
                color: $color-black;

                &:focus,
                &:hover {
                    border: none;
                    border-bottom: solid 2px $color-red;
                    color: $color-black;
                }
            }
        }
    }
}
