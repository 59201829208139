@import "../variables";

.products-list {
    &__title {
        margin-bottom: 25px;
        font-weight: 600;
        font-size: 23px;
        color: $color-black;
    }

    .product-thumb {
        border: none;

        h4 {
            margin-bottom: 15px;

            a {
                color: $color-black;

                &:hover {
                    color: $color-red;
                }
            }
        }

        .price {
            font-weight: 600;
            font-size: 18px;
            color: $color-black;
        }

        .button-group {
            border: none;
            padding: 0 20px;
            text-align: center;
            background: none;

            button {
                float: none;
                width: 100%;

                .icon {
                    vertical-align: middle;
                    margin-right: 10px;
                    fill: #888;
                }

                &:hover {
                    .icon {
                        fill: #444;
                    }
                }
            }
        }
    }

    [class*="col"] {
        padding-top: 15px;
        padding-bottom: 15px;

        &:hover {
            box-shadow: 0 17px 50px 0 rgba(40, 48, 55, 0.16);
        }
    }
}
