@import "../variables";

.buy-info {
    margin-bottom: 0;

    &__item {
        margin-bottom: 12px;
        font-size: 19px;
        color: $color-black;
    }
}
