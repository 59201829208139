.product {
    &__column {
        margin-bottom: 30px;
        max-width: 864px;

        img {
            max-width: 100%;
        }

        ul {
            list-style:none;
        }
    }
}
