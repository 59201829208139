.is-hidden {
	// stylelint-disable-next-line declaration-no-important
	display: none !important;
}

.for-desktop {
	@include mobile {
		// stylelint-disable-next-line declaration-no-important
		display: none !important;
	}
}

.for-mobile {
	@include desktop {
		// stylelint-disable-next-line declaration-no-important
		display: none !important;
	}
}

.clearfix {
	overflow: auto;

	&::after {
		content: "";
		display: table;
		clear: both;
	}
}
