.row {
    &__center {
        margin: 150px auto 0;

        .col-sm-6 {
            margin: 0 auto;
            float: none;
        }
    }
}
