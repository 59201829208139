.goods_section {
    position: relative;
    margin: 0 auto 40px;
    width: 1224px;
    max-width: 100%;

    &__headline {
        margin-bottom: 30px;
        padding-bottom: 0;
        font-weight: 600;
        font-size: 24px;
        color: $color-black;

        &::after {
            content: "";
            display: inline-block;
            min-height: 33px;
        }

        @media screen and (max-width: 1280px) {
            font-weight: 600;
            font-size: 18px;
        }

        @media screen and (max-width: 641px) {
            font-size: 14px;
            text-align: center;
        }
    }

    &__toggle {
        display: flex;
        align-items: center;
        justify-content: center;
        border: 1px solid #ddd;
        width: 34px;
        height: 34px;
        cursor: pointer;
    }

    &__controls {
        position: absolute;
        right: 0;
        top: 0;
        display: flex;
    }

    &__toggle-back {
        border-top-left-radius: 4px;
        border-bottom-left-radius: 4px;

        &::before {
            content: "";
            display: inline-block;
            width: 11px;
            height: 15.6px;
            background: url("/dist/images/sprites/svg/prev.svg") no-repeat center;
            background-size: contain;
        }
    }

    &__toggle-next {
        border-left-width: 0;
        border-top-right-radius: 4px;
        border-bottom-right-radius: 4px;

        &::before {
            content: "";
            display: inline-block;
            width: 11px;
            height: 15.6px;
            background: url("/dist/images/sprites/svg/next.svg") no-repeat center;
            background-size: contain;
        }
    }

    .swiper-slide-prev {
        opacity: 0;
        transition: opacity 0.2s cubic-bezier(0.4, 0, 1, 1);
    }

    .left-screen {
        display: none;

        @media screen and (min-width: 1280px) {
            position: absolute;
            top: 0;
            z-index: 2;
            display: block;
            width: 100%;
            height: 100%;
            background: #fff;
            transform: translate3d(-1150px, 0, 0);
        }

        @media screen and (min-width: 879px) and (max-width: 1280px) {
            position: absolute;
            top: 0;
            z-index: 2;
            display: block;
            width: 100%;
            height: 100%;
            background: #fff;
            transform: translate3d(-892px, 0, 0);
        }

        @media screen and (min-width: 641px) and (max-width: 879px) {
            position: absolute;
            top: 0;
            z-index: 2;
            display: block;
            width: 100%;
            height: 100%;
            background: #fff;
            transform: translate3d(-654px, 0, 0);
        }
    }

    .right-screen {
        display: none;

        @media screen and (min-width: 1280px) {
            position: absolute;
            top: 0;
            z-index: 2;
            display: block;
            width: 100%;
            height: 100%;
            background: #fff;
            transform: translate3d(1150px, 0, 0);
        }

        @media screen and (min-width: 879px) and (max-width: 1280px) {
            position: absolute;
            top: 0;
            z-index: 2;
            display: block;
            width: 100%;
            height: 100%;
            background: #fff;
            transform: translate3d(888px, 0, 0);
        }

        @media screen and (min-width: 641px) and (max-width: 879px) {
            position: absolute;
            top: 0;
            z-index: 2;
            display: block;
            width: 100%;
            height: 515px;
            background: #fff;
            transform: translate3d(645px, 0, 0);
        }

    }

    @media screen and (min-width: 880px) and (max-width: 1280px) {
        padding: 0;
        width: 877px;
    }

    @media screen and (min-width: 642px) and (max-width: 879px) {
        width: 640px;
    }

    @media screen and (max-width: 641px) {
        width: 100%;

        .goods_section__controls {
            top: 0;
            display: flex;
            padding-right: 20px;
        }

        .goods_section__toggle-next,
        .goods_section__toggle-back {
            position: relative;
            top: 0;
            border: 1px solid #ddd;
            border-radius: 4px;
        }

        .goods_section__toggle-back {
            border-right: 0;
            border-top-right-radius: 0;
            border-bottom-right-radius: 0;
        }

        .goods_section__toggle-next {
            border-top-left-radius: 0;
            border-bottom-left-radius: 0;
        }
    }
}

.carousel_goods-viewport {
    margin: -70px auto 0 -25px;
    padding: 90px 25px 50px;
    width: 1224px;
}

.c-goods_swiper {
    &__wrapper {
        z-index: 2;
        display: flex;

        .c-goods {
            &__name {
                overflow: hidden;
                margin-bottom: 0;
                height: 36px;
                word-break: normal;
                white-space: normal;
                text-overflow: initial;

                @media screen and (max-width: 641px) {
                    margin-bottom: 10px;
                    height: 42px;
                    font-size: 16px;
                }
            }

            &__block {
                &:hover {
                    z-index: 999;

                    .c-goods__links {
                        opacity: 1;
                        visibility: visible;
                    }
                }
            }

            &__badge {
                margin-right: 0;
                margin-left: 0;
            }

            &__addition-block {
                padding: 0 16px;
            }

            &__cover {
                padding-top: 35px;
            }

            &__price-block {
                margin-right: 0;
                margin-left: 0;

                @media screen and (max-width: 641px) {
                    margin-bottom: 15px;
                }
            }

            &__links {
                position: absolute;
                left: 0;
                top: 100%;
                margin-top: auto;
                margin-bottom: 0;
                width: 100%;
                box-shadow: 0 17px 50px 0 rgba(40, 48, 55, 0.16);
                background: #fff;
                opacity: 0;
                visibility: hidden;
            }

            &__tech-block {
                margin-bottom: 18px;

                @media screen and (max-width: 641px) {
                    margin-bottom: 10px;
                }
            }

            &__tech {
                &:last-child {
                    margin-bottom: 0;
                }

                @media screen and (max-width: 641px) {
                    justify-content: flex-start;
                }
            }

            &__others-links {
                @media screen and (max-width: 641px) {
                    display: none;
                }
            }
        }
    }

    .c-goods__block {
        flex-shrink: 0;
        margin-right: 11px;
        width: 236px;

        &.swiper-slide-active + .c-goods__block + .c-goods__block + .c-goods__block,
        &.swiper-slide-active + .c-goods__block + .c-goods__block,
        &.swiper-slide-active + .c-goods__block,
        &.swiper-slide-active {
            opacity: 1;
            visibility: visible;
        }

        @media screen and (min-width: 880px) and (max-width: 1280px) {
            margin-right: 11px;
            width: 285px;
        }

        @media screen and (min-width: 642px) and (max-width: 879px) {
            margin-right: 7px;
            width: 208.6px;
        }

        @media screen and (max-width: 641px) {
            margin-right: 7px;
            width: 204px;
            opacity: 1;
            visibility: visible;
        }
    }

    .c-goods__buy-links {
        @media screen and (max-width: 879px) {
            display: block;
        }
    }

    .c-goods__buy--button,
    .c-goods__buy-now--button {
        @media screen and (min-width: 642px) and (max-width: 879px) {
            width: 100%;

            .link {
                font-weight: 400;
                font-size: 14px;
            }
        }
    }

    @media screen and (min-width: 880px) and (max-width: 1280px) {
        margin-bottom: 40px;
    }

    @media screen and (max-width: 641px) {
        margin-bottom: 0;
        width: 204px;
    }
}

.carousel_goods {
    &-direction-nav {
        position: absolute;
        right: 24px;
        top: 0;
        display: flex;
    }

    &-nav-prev,
    &-nav-next {
        position: relative;
        display: flex;
        align-items: center;
        justify-content: center;
        border: 1px solid #ddd;
        width: 34px;
        height: 34px;
        cursor: pointer;
    }

    &-nav-prev {
        border-top-left-radius: 4px;
        border-bottom-left-radius: 4px;

        &::before {
            content: "";
            display: inline-block;
            width: 10px;
            height: 17px;
            background: url("/dist/images/sprites/svg/prev.svg") no-repeat;
            background-size: contain;
        }
    }

    &-nav-next {
        border-left-width: 0;
        border-top-right-radius: 4px;
        border-bottom-right-radius: 4px;

        &::before {
            content: "";
            display: inline-block;
            width: 10px;
            height: 17px;
            background: url("/dist/images/sprites/svg/next.svg") no-repeat;
            background-size: contain;
        }
    }

    &-prev,
    &-next {
        position: absolute;
        display: block;
        width: 100%;
        height: 100%;
    }
}

.swiper-button-disabled {
    opacity: 0.6;
    cursor: default !important;
}
