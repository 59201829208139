@mixin desktop {
	@media (min-width: (1025px)) {
		@content;
	}
}

@mixin mobile {
	@media (max-width: (1024px)) {
		@content;
	}
}
