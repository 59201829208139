.c-goods {
    &__tech-block {
        display: flex;
        flex-direction: column;
        margin-bottom: 18px;
        line-height: (18 / 12);

        @media screen and (max-width: 879px) {
            line-height: 18px;
        }

        @media screen and (max-width: 641px) {
            display: block;
            height: auto;
        }
    }

    &__tech {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-bottom: 10px;

        @media screen and (max-width: 641px) {
            flex-direction: column;
            margin-bottom: 10px;
        }
    }

    &__key {
        width: 98px;
        font-size: 12px;
        line-height: 1.4;
        word-wrap: break-word;
        hyphens: manual;
        color: #808080;

        @media screen and (min-width: 880px) and (max-width: 1280px) {
            font-size: 10px;
        }

        @media screen and (max-width: 879px) {
            width: 90px;
            font-size: 10px;
        }

        @media screen and (max-width: 641px) {
            width: 100%;
            font-size: 12px;
        }
    }

    &__val {
        margin-left: 10px;
        width: 98px;
        font-weight: 500;
        font-size: 12px;
        line-height: 1.4;
        word-wrap: break-word;
        hyphens: manual;
        color: #000;

        @media screen and (max-width: 641px) {
            max-width: none;
        }

    }
}
